import React from 'react'
import AttendanceComponent from './AttendanceComponent';
import Navbar from './Navbar'

export default function main() {

    const students = [
        {
            "id": "RA2011003030213",
            "name": "Yasir Mansoori",
            "email": "ym2745@srmist.edu.in"
        },
        {
            "id": "RA20110030302",
            "name": "CD",
            "email": "cd@srmist.edu.in"
        },
        {
            "id": "RA20110030303",
            "name": "EF",
            "email": "ef@srmist.edu.in"
        },
        {
            "id": "RA20110030304",
            "name": "GH",
            "email": "gh@srmist.edu.in"
        },
        {
            "id": "RA20110030305",
            "name": "IJ",
            "email": "ij@srmist.edu.in"
        }
    ]

    return (

        <div>
            <Navbar />
            <AttendanceComponent students={students} />
        </div>
    )
}





