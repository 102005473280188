import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Login() {
    const students = [
        {
            "id": "RA2011003030213",
            "name": "Yasir Mansoori",
            "email": "ym2745@srmist.edu.in"
        },
        {
            "id": "RA2011003030117",
            "name": "Srishti Dingra",
            "email": "sd9114@srmist.edu.in"
        },
        {
            "id": "RA2011003030195",
            "name": "Vani Saraswat",
            "email": "vs3300@srmist.edu.in"
        },
        {
            "id": "test",
            "name": "test",
            "email": "test@test"
        }
    ]
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [email, setEmail] = useState('');
    // eslint-disable-next-line
    const [reg, setReg] = useState('');
    // eslint-disable-next-line
    const [error, setError] = useState('');
    const showToast = () => {
        toast.error('Email or registration number is incorrect');
    };
    const handleLogin = () => {
        var emaild = document.getElementById("email").value;
        var regd = document.getElementById("pass").value;
        const matchedStudentemail = students.find(student => student.email === emaild);
        const matchedStudentreg = students.find(student => student.id === regd);

        if (matchedStudentemail && matchedStudentreg) {
            console.log(emaild);
            console.log(regd);
            navigate('/home');

        } else {
            console.log('Showing error toast');
            setError('Email or registration number is incorrect');
            setEmail('');
            setReg('');
            showToast();
        }
    };
    return (

        <div className='container' >
            <section className="vh-100" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="container-fluid h-custom">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-md-9 col-lg-6 col-xl-5">
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                                className="img-fluid" alt="Sample" />
                        </div>
                        <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                            <form>
                                <div className="form-outline mb-4">
                                    <input type="email" id="email" className="form-control form-control-lg"
                                        placeholder="Enter Your College email address" autoComplete='username' required />
                                    <label className="form-label mt-2" htmlFor="email">Email address</label>
                                </div>

                                <div className="form-outline mb-3">
                                    <input type="password" id="pass" className="form-control form-control-lg"
                                        placeholder="Enter Your Registration Number" autoComplete='current-password' required />
                                    <label className="form-label mt-2" htmlFor="pass" >Registration Number</label>
                                </div>
                                <div className="text-center text-lg-start pt-2">
                                    <button type="button" onClick={handleLogin} className="btn btn-primary btn-lg"
                                        style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}>Login</button>
                                </div>
                                {/* {error && <ToastContainer />} */}
                                <ToastContainer />
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

