import React, { useState } from 'react';
const AttendanceComponent = () => {
  const [attendanceData, setAttendanceData] = useState([
    {
      id: '18CSE455T',
      description: 'D.B.M.S',
      totalPercentage: '75%',
      date: new Date().toLocaleDateString(),
      present: false,
      absent: false,
    },
    {
      id: '18CSE458T',
      description: 'Compiler',
      totalPercentage: '75%',
      date: new Date().toLocaleDateString(),
      present: false,
      absent: false,
    },
    {
      id: '18CSE459T',
      description: 'O.S',
      totalPercentage: '75%',
      date: new Date().toLocaleDateString(),
      present: false,
      absent: false,
    },
    {
      id: '18CSE460T',
      description: 'D.A.A',
      totalPercentage: '75%',
      date: new Date().toLocaleDateString(),
      present: false,
      absent: false,
    },
  ]);

  const handleAttendanceChange = (index, action) => {
    const updatedAttendanceData = [...attendanceData];
    if (action === 'present') {
      updatedAttendanceData[index].present = true;
      updatedAttendanceData[index].absent = false;
    } else if (action === 'absent') {
      updatedAttendanceData[index].present = false;
      updatedAttendanceData[index].absent = true;
    }
    setAttendanceData(updatedAttendanceData);
  };

  const handleSubmit = () => {
    const presentCount = attendanceData.filter((item) => item.present).length;
    const absentCount = attendanceData.filter((item) => item.absent).length;
    console.log('Present Count:', presentCount);
    console.log('Absent Count:', absentCount);
  };
  const handlelogout = () => {
    window.location.assign("./")
  }

  return (
    <div className='container'>
      <div className="container">
        <h3 className='mt-3' style={{ fontFamily: 'Poppins' }} >Hey Student, Mark your attendance</h3>
      </div>
      <div className="accordion mt-3" id="accordionExample">
        {attendanceData.map((item, index) => (
          <div className="accordion-item" key={index}>
            <h2 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`} >
                <h5>{item.description}</h5>
              </button>
            </h2>
            <div id={`collapse${index}`} className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div className="accordion-body" style={{ overflowX: 'auto' }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Code</th>
                      <th scope="col">Description</th>
                      <th scope="col">Total Percentage</th>
                      <th scope="col">Date</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">{item.id}</th>
                      <td>{item.description}</td>
                      <td>{item.totalPercentage}</td>
                      <td>{item.date}</td>
                      <td>
                        <button type="button" className="btn btn-primary" style={{ marginRight: '10px' }} onClick={() => handleAttendanceChange(index, 'present')} disabled={item.present}>Present</button>
                        <button type="button" className="btn btn-secondary" onClick={() => handleAttendanceChange(index, 'absent')} disabled={item.absent}>Absent</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button type="button" className="btn btn-primary mt-4 mb-4" onClick={handleSubmit} data-bs-toggle="modal" data-bs-target="#staticBackdrop">Submit Attendance</button>
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Record</h1>
              <button type="button" className="btn-close" onClick={handlelogout} data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Your Attendance has been recorded!
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handlelogout} data-bs-dismiss="modal">Thanks</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceComponent;